import axios from "axios";
import filterPosts from "../common/filter-posts";
import preLoader from "./preloader";
import dragSlider from "./slider";
import changeForm from "./changeForm";
const loadLecturesTemplate = () => {
  const lecturesContainer = document.getElementById("lecturesPage");
  if (!lecturesContainer) return;

  const getMode = () =>
    window.innerWidth > mobileWidth ? "desktop" : "mobile";

  let mobileWidth = 1024;
  let mode = getMode();

  const getTemplate = () => {
    console.log("load");

    if (preLoader.isLoading) return;

    preLoader.loading(true);

    const data = new FormData();
    data.append("action", "drweb_get_lectures_template");
    data.append("mode", mode);

    // INSERT POSTS INTO CONTAINER
    axios.post(BAMBOO.ajaxUrl, data).then((response) => {
      lecturesContainer.innerHTML = response.data;
      filterPosts();
      setTimeout(changeForm, 100);
      preLoader.loading(false);
      if (mode == "mobile") {
        lecturesContainer.classList.add("lectures-slider");
        setTimeout(dragSlider, 100);
      } else lecturesContainer.classList.remove("lectures-slider");
    });
  };

  const checkResize = () => {
    if (mode !== getMode()) {
      mode = getMode();
      getTemplate();
    }
  };

  window.addEventListener("resize", checkResize);
  window.addEventListener("load", getTemplate);
};

export default loadLecturesTemplate;
