// Detect browser
const { detect } = require('detect-browser');
const browser = detect();

// Add browser to html class
document.getElementsByTagName("html")[0].classList.add(browser ? browser.name : "");

// Detect webp support && add class to body
document.addEventListener('DOMContentLoaded', () => {
  var i = new Image; i.onload = i.onerror = function () { document.body.classList.add(i.height == 1 ? "webp" : "no-webp") }; i.src = "data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==";
});

/**
 * TODO (add avif support)
 */
// function AddClass(cl) {
//   document.body.classList.add(cl);
// };

// const checkImgSupport = () => {

//   var avif = new Image();

//   avif.src = "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=";

//   avif.onload = function () {
//     AddClass('avif');
//     // AddClass('no-webp');
//   };

//   avif.onerror = function () {

//     AddClass('no-avif');
//     var webp = new Image();

//     webp.src = "data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==";

//     webp.onload = function () {
//       AddClass('webp');
//     }

//     webp.onerror = function () {
//       // AddClass('no-webp');
//     }

//   }
// }

// checkImgSupport();

