import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

const invertedSections = document.querySelectorAll('section.header-invert');
const header = document.getElementById("navHeader");

export default function invertHeader() {

  const toggleHeader = (e) => header.classList.toggle('inverted', !e.isActive);

  const initScrollTrigger = (el) => {
    ScrollTrigger.create({
      trigger: el,
      onToggle: toggleHeader,
      onUpdate: toggleHeader
    });
  }

  invertedSections.length && [...invertedSections].forEach(s => initScrollTrigger(s));
};
