import axios from "axios";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const form = document.getElementById("loginform");

export default function loginForm() {
  if (form) {
    let isFetching = false;
    const popUp = form.closest(".pop-up");
    const closeError = popUp.querySelector(".success_msg_close");
    const errorTitle = popUp.querySelector(".success_msg h2");
    const errorSubtitle = popUp.querySelector(".success_msg p");
    const invalidLabels = form.querySelectorAll("p .invalid");
    const inputs = form.querySelectorAll("p input");

    let rememberMe = document.getElementById("rememberme");

    (function () {
      if (rememberMe.value) {
        rememberMe.closest("label").classList = "checked";
      }

      rememberMe.addEventListener("change", (e) => {
        let val = rememberMe.value !== "" ? "" : "forever";

        e.target.closest("label").classList.toggle("checked", val);
        rememberMe.value = val;
      });
    })();

    form.onsubmit = (e) => {
      e.preventDefault();

      if (isFetching) return;
      isFetching = true;

      removeErrors();

      const data = new FormData(form);
      data.append("action", "drweb_ajax_login");
      data.append("remember", rememberMe.value);

      axios.post(BAMBOO.ajaxUrl, data).then((response) => {
        let errors = response.data.errors;

        if (!errors) {
          window.location.reload();
        } else {
          // Invalid or empty inputs
          if (errors.fields) {
            const fields = Object.entries(errors.fields);
            fields.forEach((error) => createErrors(error));
          }

          // User not activated | Other error
          if (errors.message) {
            errorForm("Error", errors.message, 0);
          }
        }

        isFetching = false;
      });
    };

    const createErrors = (e) => {
      let l = [...invalidLabels].find((i) => i.classList.contains(e[0]));
      l.innerHTML = e[1];
      l.classList.add("active");
    };

    const removeErrors = () => {
      [...invalidLabels].forEach((l) => {
        l.classList.remove("active");
        setTimeout(() => {
          l.innerHTML = " ";
        }, 200);
      });
    };

    closeError.addEventListener("click", (e) => {
      enableBodyScroll(document.body);
      errorForm(" ", " ", 400)
    });

    inputs.forEach((input) => {
      input.addEventListener("input", (e) => {
        input.nextElementSibling.classList.remove("active");
      });
    });

    const errorForm = (t, s, d) => {
      setTimeout(() => {
        popUp.classList.toggle("error");
        errorTitle.innerText = t;
        errorSubtitle.innerText = s;
      }, d);
    };
  }
}
