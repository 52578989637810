import axios from "axios";

export default function logout() {
  const logout = document.querySelectorAll('[data-logout]');
  if (!logout.length) return;

  [...logout].forEach(button => {
    button.addEventListener('click', () => {
      let data = new FormData();
      data.set('action', 'drweb_ajax_logout');

      axios.post(BAMBOO.ajaxUrl, data).then(response => {
        (response.data === 'logout') && window.location.reload();
      });
    });
  });
};
