import gsap from "gsap";

const preloader = document.querySelector('.preloader');

const animate = gsap.timeline({
  paused: true,
  onComplete() {
    preLoader.isLoaded = true;
  },
  onReverseComplete() {
    preLoader.isLoaded = false;
  }
})
  .to(preloader, { duration: 0.75, autoAlpha: 0 });

const preLoader = {
  isLoading: false,
  isLoaded: false,
  on() {
    if (this.isLoaded) {
      animate.timeScale(4).reverse();
    }
  },
  off() {
    setTimeout(() => {
      if (!this.isLoading) {
        animate.timeScale(1).play();
      }
    }, 500);
  },
  loading(bool) {
    this.isLoading = bool;
    if (!this.isLoading) {
      this.off();
    } else {
      this.on();
    }
  }
}

export default preLoader;