// import axios from "axios";
import gsap from "gsap";

const filterPosts = () => {
  // const container = document.getElementById("postsContainer");

  const tabs = [...document.querySelectorAll("[data-tag]")];
  if (!tabs.length) return;

  const lectures = document.querySelectorAll(".slider_lectures, .lectures_grid .grid");
  let currentSlider = -1;

  // const getTagPosts = (term_id) => {
  //   const data = new FormData();
  //   data.append("action", "drweb_filter_lectures");
  //   data.append("term_id", term_id);

  //   // INSERT POSTS INTO CONTAINER
  //   axios.post(BAMBOO.ajaxUrl, data).then((response) => {
  //     if (container) {
  //       container.innerHTML = response.data;
  //     }
  //   });
  // };

  const setActiveTag = (active) => {
    tabs.forEach((tab) => {
      tab.classList.toggle("active", tab.dataset.tag === active);
    });
  };
  const setActiveSlider = (active) => {

    if (currentSlider !== active) {

      gsap.timeline({
        onComplete: () => { currentSlider = active; }
      })
        .set(lectures[active], { position: 'relative' })
        .to(lectures[currentSlider], { duration: 0.5, autoAlpha: 0 })
        .set(lectures[currentSlider], { position: 'absolute' })
        .to(lectures[active], { duration: 0.3, autoAlpha: 1 })
    }

  };

  const getActiveTag = (tab) => {
    let active = tab.dataset.tag;
    setActiveTag(active);
    // getTagPosts(active);
  };

  tabs.forEach((tab, i) => {
    tab.addEventListener("click", (e) => {
      e.preventDefault();
      getActiveTag(tab);
      setActiveSlider(i);
    });
  });

  setActiveSlider(0);
};

export default filterPosts;
