import axios from "axios";

export default function registerForm() {
  const form = document.getElementById("registerform");
  if (!form) return;

  const toggleFormButtons = document.querySelectorAll("[data-register]");
  const popUp = form.closest(".pop-up");
  const invalidLabels = form.querySelectorAll("p .invalid");
  const inputs = form.querySelectorAll("p input");
  const step1 = form.querySelector(".step-1");
  const step2 = form.querySelector(".step-2");
  const nextButton = popUp.querySelector("[data-next-step]");
  const submit = form.querySelector('[type="submit"]');
  const dropArea = step2.querySelector(".reg-file");
  const file = dropArea.querySelector('[type="file"]');
  const fileName = step2.querySelector(".fileName");
  const stepNum = document.getElementById("currentStep");
  const prevStep = popUp.querySelector(".prevStep");

  const validateStep1 = () => {
    // showStep2();

    removeErrors();

    const data = new FormData(form);
    data.append("action", "drweb_ajax_validate_step");

    axios.post(BAMBOO.ajaxUrl, data).then((response) => {
      if (response.data !== "") {
        const fields = Object.entries(response.data);
        fields.forEach((error) => createErrors(error));
      } else {
        showStep2();
      }
    });
  };

  const showStep1 = () => {
    stepNum.innerHTML = "1";
    step1.classList.add("active");
    step2.classList.remove("active");
    nextButton.classList.remove("hidden");
    prevStep.classList.add("hidden");
    submit.disabled = true;
  };

  const showStep2 = () => {
    stepNum.innerHTML = "2";
    step1.classList.remove("active");
    step2.classList.add("active");
    nextButton.classList.add("hidden");
    prevStep.classList.remove("hidden");
    submit.disabled = false;
  };

  form.onsubmit = (e) => {
    e.preventDefault();

    removeErrors();

    let data = new FormData(form);
    data.append("action", "drweb_create_user");

    axios.post(BAMBOO.ajaxUrl, data).then((response) => {
      // console.log(response.data);
      if (response.data.errors) {
        const fields = Object.entries(response.data.errors);
        fields.forEach((error) => createErrors(error));
      }

      response.data.success && successForm();
    });
  };

  nextButton.onclick = () => validateStep1();

  file.addEventListener("change", (e) => {
    let file = e.target.files[0];
    fileName.innerHTML = file ? file.name : "Upload file";
  });

  file.addEventListener("dragenter", highlight);

  ["dragleave", "drop"].forEach((e) => file.addEventListener(e, unHighlight));

  function highlight(e) {
    dropArea.classList.add("highlight");
  }

  function unHighlight(e) {
    dropArea.classList.remove("highlight");
  }

  inputs.forEach((input) => {
    input.addEventListener("input", (e) => {
      input.nextElementSibling.classList.remove("active");
    });
  });

  const createErrors = (e) => {
    let l = [...invalidLabels].find((i) => i.classList.contains(e[0]));
    l.innerHTML = e[1];
    l.classList.add("active");
  };
  const removeErrors = () => {
    [...invalidLabels].forEach((l) => {
      l.classList.remove("active");
      setTimeout(() => {
        l.innerHTML = " ";
      }, 200);
    });
  };
  const openForm = () => {
    showStep1();
  };

  const closeForm = () => {
    showStep1();
    popUp.classList.remove("sent");
    removeErrors();
  };

  const successForm = () => {
    popUp.classList.add("sent");
    popUp.querySelectorAll("p input").forEach((i) => (i.value = ""));
    fileName.value = "Upload file";
  };

  prevStep.addEventListener("click", showStep1);

  toggleFormButtons.forEach((b) => {
    b.addEventListener("click", (e) => {
      if (popUp.classList.contains("active")) {
        closeForm();
      } else {
        openForm();
      }
    });
  });
}
