import Swiper from "swiper";

export default function dragSlider() {
  const sliders = document.querySelectorAll(".drag_swiper_slider");
  if (!sliders) return;

  sliders.forEach((dragSlider) => {
    let dragWidth,
      translate,
      index = 0,
      width = 0,
      isTranslate = true,
      speedTime = 400,
      stonesCount = dragSlider.querySelectorAll(".drag_slide"),
      scrollbar = dragSlider.querySelector(".swiper-scrollbar-drag");

      if ((stonesCount[0].clientWidth * (stonesCount.length + 1)) < stonesCount[0].parentNode.clientWidth) return;


    const swiper = new Swiper(dragSlider, {
      slidesPerView: "auto",
      speed: speedTime,
      loop: true,
      grabCursor: true,
      loopedSlides: 10,
      sticky: true,
      on: {
        init: (e) => {
          dragWidth = 100 / stonesCount.length;
          scrollbar.style.width = dragWidth + "%";
          width = e.translate;
        },
        progress: (e) => {
          if (isTranslate) moveScrollbar(e);
        },
        resize: (e) => {
          setTimeout(() => {
            e.update();
            width = -e.snapGrid[stonesCount.length];
            moveScrollbar(e);
          }, 1000);
        },
        sliderFirstMove: (e) => {
          scrollbar.style.transitionDuration = "0s";
        },
        touchEnd: (e) => {
          isTranslate = false;

          setTimeout(() => {
            moveScrollbar(e);
            scrollbar.style.transitionDuration = speedTime + "ms";
          }, 0);
        },
        slideChangeTransitionEnd: (e) => {
          scrollbar.style.transitionDuration = "0s";
        },
      },
    });

    function moveScrollbar(e) {
      index = 0;

      translate = ((e.translate - width) / width) * 100;

      if (translate > 100 - dragWidth / 2) index = -100;
      if (translate < -dragWidth / 2) index = 100;

      translate = translate + index;
      scrollbar.style.left = translate + "%";
      isTranslate = true;
    }

    function dragScrollbar(elmnt) {
      let swiperTranslateLast,
        pxToPercent,
        leftPosition,
        styleLeft,
        translateTo,
        scrollbarStyleWidth = scrollbar.parentNode.clientWidth,
        swiperTranslateFirst = swiper.translate,
        snapGrid = swiper.snapGrid,
        activeSlide,
        index = 0,
        pos1 = 0,
        pos3 = 0,
        indexTranslate = 0;

      elmnt.onmousedown = dragMouseDown;

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        isTranslate = false;
        pos3 = e.clientX;

        leftPosition = scrollbar.style.left.split("%")[0];
        swiperTranslateLast = swiper.translate;

        scrollbar.classList.add("drag");

        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        indexTranslate = 0;
        index = 0;

        // activeSlide = dragSlider.querySelector(
        //   ".drag_slide.swiper-slide-active"
        // );

        // if (activeSlide) activeSlide.classList.remove("swiper-slide-active");

        pos1 = e.clientX - pos3;
        pxToPercent = (pos1 * 100) / scrollbarStyleWidth;

        translateTo =
          swiperTranslateLast + swiperTranslateFirst * (pxToPercent / 100);
        if (translateTo <= 2 * swiperTranslateFirst)
          indexTranslate = -swiperTranslateFirst;
        if (translateTo >= 0) indexTranslate = swiperTranslateFirst;
        swiper.translateTo(translateTo + indexTranslate, 0);

        styleLeft = +leftPosition + pxToPercent;
        if (styleLeft > 100 - dragWidth / 2) index = -100;
        if (styleLeft < -dragWidth / 2) index = 100;

        elmnt.style.left = styleLeft + index + "%";
        elmnt.style.transitionDuration = "0s";
      }

      function closeDragElement() {
        scrollbar.classList.remove("drag");
        document.onmouseup = null;
        document.onmousemove = null;
        changeSlide();
      }

      function changeSlide() {
        let goal = Math.abs(swiper.translate);
        let i = swiper.snapGrid.indexOf(
          swiper.snapGrid.reduce((prev, curr) =>
            Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
          )
        );

        swiper.slideTo(i, speedTime);
        scrollbar.style.transitionDuration = speedTime + "ms";
        moveScrollbar(swiper);
      }
    }
    dragScrollbar(scrollbar);
  });
}
