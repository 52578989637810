import { gsap, ScrollTrigger, ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const scrollToAnchors = () => {
  const navHeader = document.querySelector('#navHeader');
  if(!navHeader) return;
  
  const anchors = [...document.querySelectorAll('a[href*="#"]')];
  const navAnchors = anchors.filter(el => el.closest('nav'));
  const sections = navAnchors.map(anchor => document.querySelector(anchor.hash));
  const topOffset = navHeader.clientHeight;
  let onClick = false;

  const toggleActiveAnchor = (hash) => {
    navAnchors.forEach(el => {
      el.classList.toggle('active', hash === el.hash);
    });
  }

  const clickAnchor = (link) => {
    link.addEventListener('click', e => {

      let target = document.querySelector(link.hash);

      if (target) {
        onClick = true;
        toggleActiveAnchor(link.hash);
        e.preventDefault();
        gsap.to(window, {
          duration: 0.75, scrollTo: { y: target, offsetY: topOffset }, ease: "expo.out", onComplete() {
            onClick = false;
          }
        });
      }
    })
  }

  const toggleOnScroll = () => {
    if (sections.length) {
      sections.forEach(section => {
        ScrollTrigger.create({
          trigger: section,
          start: `top ${topOffset * 2}px`,
          end: () => `+=${section.clientHeight}px`,
          // markers: true,
          onToggle: (self) => {
            if (!onClick) {
              let target = self.isActive ? `#${section.id}` : null;
              toggleActiveAnchor(target);
            }
          }
        });
      })
    }
  }

  if (anchors) {
    anchors.forEach(link => clickAnchor(link));
    toggleOnScroll();
  }
}

export default scrollToAnchors;