import axios from 'axios';
import { closeMenu, openMenu } from "./toggle-menu";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default function subscribeRequest() {

  const conferences = document.querySelectorAll('[data-subscribe]');
  if (!conferences.length) return;

  const confirmPopup = document.querySelector('.pop-up_requested');
  const submit = confirmPopup.querySelector('[data-submit]');
  const popupTitle = confirmPopup.querySelector('h2');
  const popupText = confirmPopup.querySelector('p');
  const closePopup = confirmPopup.querySelector('[data-requested]');

  const currentTitle = popupTitle.textContent;
  const currentText = popupText.textContent;

  let isFetching = false;

  const requestSubscribe = (c) => {

    if (isFetching) return;
    isFetching = true;

    let userID = c.dataset.subscribe;
    let postID = c.dataset.post;
    let postTitle = c.querySelector('.title').innerText;

    let data = new FormData();
    data.append('user_id', userID);
    data.append('post_id', postID);
    data.append('post_title', postTitle);
    data.append('action', 'drweb_ajax_subscribe');

    axios.post(BAMBOO.ajaxUrl, data).then(response => {
      popupTitle.innerText = response.data.message;
      popupText.innerText = '';

      c.classList.remove('disabled');
      isFetching = false;
    });
  }

  const requestSubmit = (c) => {
    openMenu();
    confirmPopup.classList.add('active');
    c.classList.add('disabled');

    submit.onclick = () => {
      closeMenu();
      requestSubscribe(c);
    }
  }

  [...conferences].forEach(c => {
    c.onclick = () => { requestSubmit(c) };
  });

  closePopup.addEventListener('click', () => {
    closeMenu();
    popupTitle.innerText = currentTitle;
    popupText.innerText = currentText;
  });


};
