import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const header = document.getElementById("navHeader");

const openMenu = () => {
  disableBodyScroll(document.body);
};
const closeMenu = () => {
  enableBodyScroll(document.body);
};

const toggleMainMenu = () => {
  if (!header) return;
  const button = header.querySelector(".mob_main");
  const tabs = header.querySelectorAll("header nav a");
  const logout = header.querySelector(".buttons .logout");

  const toggle = (el) => el.classList.toggle("active");
  tabs.forEach((t) => t.addEventListener("click", (e) => {
    if (header.classList.contains("active")) {
      closeMenu();
      header.classList.remove("active");
    }
    })
  );
  logout && logout.addEventListener("click", (e) => toggle(logout));

  button.addEventListener("click", (e) => {
    if (header.classList.contains("active")) {
      closeMenu();
      header.classList.remove("active");
    } else {
      openMenu();
      header.classList.add("active");
    }
  });
};

export default toggleMainMenu;

export { closeMenu, openMenu };
