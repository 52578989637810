import all from "./common/utilities";
import dragSlider from "./common/slider";
import scrollToAnchors from "./common/scroll-to-anchors";
import filterPosts from "./common/filter-posts";
import loadLectures from "./common/load-lectures";
import preLoader from "./common/preloader";
import toggleMainMenu from "./common/toggle-menu";
import loginForm from "./common/loginForm"
import registerForm from "./common/registerForm";
import changeForm from "./common/changeForm";
import logout from "./common/logout";
import subscribeRequest from "./common/subscribeRequest";
import invertHeader from "./common/invertHeader";

document.addEventListener("DOMContentLoaded", (e) => {
  loadLectures();
  dragSlider();
  scrollToAnchors();
  filterPosts();
  toggleMainMenu();
  loginForm();
  registerForm();
  changeForm();
  logout();
  subscribeRequest();
  invertHeader();

  preLoader.off();
});
