import { closeMenu, openMenu } from "./toggle-menu";

export default function changeForm() {
  const popups = document.querySelectorAll(".pop-up");
  if (!popups.length) return;

  const header = document.getElementById("navHeader");
  const toggleFormButtons = document.querySelectorAll("[data-login], [data-register], [data-requested]");

  toggleFormButtons.forEach((button) => {
    button.addEventListener("click", (e) => {
      header.classList.remove("active");
      let isClose = button.classList.contains("close_pop-up") || button.classList.contains("success_msg_close");
      let o = [...popups].find(p => p.classList.contains(button.dataset.open));
      let c = [...popups].find(p => p.classList.contains(button.dataset.close));
      !isClose ? openMenu() : closeMenu();
      o && openForm(o);
      c && closeForm(c);
    });
  });

  const openForm = (elem) => {
    elem.classList.add("active");
  };

  const closeForm = (elem) => {
    elem.classList.remove("active");
  };

  document.addEventListener('keydown', function (e) {
    if (e.key === "Escape") {
      closeMenu();
      popups.forEach(p => closeForm(p))
    }
  });


}
